import { Switch, Route, BrowserRouter } from 'react-router-dom'
import { AppPaths } from './constants/paths'
import PressPage from './pages/PressPage'
import ReleasePage from './pages/ReleasePage'
import StartPage from './pages/StartPage'

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={AppPaths.Press}>
          <PressPage />
        </Route>

        <Route path={AppPaths.LatestRelease}>
          <ReleasePage />
        </Route>

        <Route path={AppPaths.Home}>
          <StartPage />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default App
