import classNames from 'classnames';
import React, { useState } from 'react';
import AudioPlayer from '../AudioPlayer';
import styles from './AudioPlayerSection.module.scss';
import albumart from '../../images/cover.jpg';
import HeroLink from '../HeroLink/HeroLink';

interface AudioPlayerSectionProps {}

export default function AudioPlayerSection({}: AudioPlayerSectionProps) {
  const tracks = [
    {
      src: 'https://soundcloud.com/majorelleblue/01-keep-up-appearances',
      title: 'Majorelle Blue - Keep Up Appearances (03:15)',
    },
    {
      src: 'https://soundcloud.com/majorelleblue/02-waterproof-mascara-1',
      title: 'Majorelle Blue - Waterproof Mascara (04:47)',
    },
    {
      src: 'https://soundcloud.com/majorelleblue/03-political-copycat',
      title: 'Majorelle Blue - Political Copycat (03:36)',
    },
    {
      src: 'https://soundcloud.com/majorelleblue/04-dreaming-with-you',
      title: 'Majorelle Blue - Dreaming With You (03:26)',
    },
    {
      src: 'https://soundcloud.com/majorelleblue/05-inheritance-1',
      title: 'Majorelle Blue - Inheritance (04:13)',
    },
  ];

  const [currentTrackId, setCurrentTrackId] = useState(0);

  return (
    <div className={styles.root}>
      <div className={classNames([styles.section, styles.coverSection])}>
        <img src={albumart} alt="Waterproof Mascara EP cover" />
      </div>

      <div className={classNames([styles.section, styles.audioSection])}>
        <ul className={styles.tracks}>
          {tracks.map((track, i) => {
            return (
              <li
                className={classNames([
                  styles.track,
                  i === currentTrackId && styles.track__selected,
                ])}
                onClick={() => setCurrentTrackId(i)}
              >
                {track.title}
              </li>
            );
          })}
        </ul>

        <AudioPlayer src={tracks[currentTrackId].src} />
      </div>
    </div>
  );
}
