import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import styles from './YoutubePlayer.module.scss'

interface YoutubePlayerProps {
  embedId: string
}

export default function YoutubePlayer({ embedId }: YoutubePlayerProps) {
  const rootRef = useRef<HTMLDivElement>(null)
  const [size, setSize] = useState({ w: 0, h: 0 })

  useLayoutEffect(() => {
    const playerWidth =
      rootRef?.current?.parentElement?.offsetWidth || window.innerWidth
    const playerHeight = playerWidth * 0.5625

    setSize({ w: playerWidth, h: playerHeight })
  }, [rootRef])

  return (
    <div className={styles['root']} ref={rootRef}>
      {rootRef.current && (
        <iframe
          width={size.w}
          height={size.h}
          src={`https://www.youtube.com/embed/${embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      )}
    </div>
  )
}
