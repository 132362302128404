import React from 'react'

interface PlayProps {}

export default function Play({}: PlayProps) {
    return (
        <svg
            width="30"
            height="32"
            viewBox="0 0 30 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7 1.86852L28.1972 16L7 30.1315L7 1.86852Z"
                stroke="white"
                strokeWidth="2"
            />
        </svg>
    )
}
