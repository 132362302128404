import Container from '../../components/Container';
import {
  ContainerSize,
  ContainerTheme,
} from '../../components/Container/Container';
import PressQuote from '../../components/PressQuote';

import styles from './PressPage.module.scss';
import logo from '../../images/logo.svg';
import AudioPlayerSection from '../../components/AudioPlayerSection';
import React from 'react';
import TextBlock from '../../components/TextBlock';
import HeroLink from '../../components/HeroLink';
import Squiggle from '../../components/Squiggle';
import { SquiggleTheme } from '../../components/Squiggle/Squiggle';

interface PressPageProps {}

export default function PressPage({}: PressPageProps) {
  return (
    <>
      <Container
        theme={ContainerTheme.White}
        size={ContainerSize.Large}
        dontCenterInner
        keepSmall
        className={styles.headerSquiggle}
      >
        <div>
          <img src={logo} alt="Band logo" />
        </div>
      </Container>

      <Container
        theme={ContainerTheme.White}
        size={ContainerSize.Large}
        dontCenterInner
        className={styles.quoteSquiggle}
      >
        <>
          <PressQuote
            quote="Catchy, smart, and in-your-face indie pop."
            source="Roque Ruiz, label manager and indie critic, Cloudberry Records"
          />

          <PressQuote
            quote="With a synth line that is more contagious than the worst of viruses, ingeniously simple lyrics and a sound that makes an old indie rocker warm and fuzzy inside, only half the brilliance of “Not as cool (as Kathleen Hanna)” would’ve been enough."
            source="Madeleine Bergquist, editor in chief, Hymn Magasin"
          />

          <PressQuote
            quote="The EP “Nostalgia Porn” plays on all of your heartstrings in creating the perfect 20 minute long escape. An escape to a post COVID indie club, where it’s guaranteed that many of these amazing songs will be played."
            source="Gustav Stjernkvist, journalist, Kult Magasin"
          />

          <PressQuote
            quote="Slick shoegaze pop from Malmö that completely breaks me."
            source="Tony Pop, festival producer, Make it Sound Umeå"
          />
        </>
      </Container>

      <Container
        size={ContainerSize.Large}
        theme={ContainerTheme.Blue}
        topMask={ContainerTheme.White}
        bottomMask={ContainerTheme.White}
        isMaskOverlaid
        dontCenterInner
        keepSmall={true}
      >
        <>
          <AudioPlayerSection />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <HeroLink
              href="https://open.spotify.com/artist/6TNEud8QNzjmtkZSCSW85v?si=MWX4itU-RPOH8_Zbw6DyYA"
              text="Hear more on Spotify"
              target="_blank"
              isSmall
            />
          </div>
          <br />
          <br />
          <br />
        </>
      </Container>

      <Container size={ContainerSize.Small} theme={ContainerTheme.White}>
        <>
          <TextBlock>
            <>
              <p>
                It all started with <b>Nellie</b> and her bleached, blue hair.
                When deciding on a band name, the 5 members did what dorks do
                best - they got out the post-its and had a formal workshop.
                After hours of discussions, vision boards and heaps of crumbled
                up sticky notes, they were still stuck. As a last resort they
                went on Wikipedia in search of blue hues and found “Majorelle
                Blue”. <b>Sofia</b>, <b>Adam</b>, <b>Nils</b> and <b>Kotte</b>{' '}
                loved it, and Nellie promised to keep dyeing her hair blue
                forever.
              </p>

              <p>
                The color “Majorelle Blue” turned out to be named after the
                artist Jaques Majorelle. In the early 1900’s, he went to the
                then colonized Morocco, saw a lot of beautifully blue houses and
                decided to name that color after himself. The band (naturally)
                decided to colonize Jaques Majorelle.
              </p>

              <p>
                With their own special blend of soft and harsh, they are often
                put in context with indie pop and riot grrrl bands of the early
                nineties and noughties. Their own influences include artists
                like Alvvays, Pixies, PJ Harvey, Mike Krol, Cocteau Twins and
                anything featuring Kathleen Hanna.
              </p>

              <p>
                So far, Majorelle Blue has released three EP’s and two
                stand-alone singles that all have been well received by fans and
                critics alike. They have been gaining international attention on
                blogs and playlists, as well as being picked up by radio in both
                Spain and Germany. Currently, they are hard at work on new
                material that is set to be released late summer 2023.
              </p>
            </>
          </TextBlock>

          <div>
            <HeroLink
              href="mailto:contact@majorelleblue.com"
              text="Contact us"
              isSmall
            />

            <Squiggle theme={SquiggleTheme.Blue1} isSmall />

            <HeroLink
              href="https://drive.google.com/drive/folders/16MMMKWDH_znRbQgTYrMEaQvCrefyOfEk?usp=sharing"
              text="Press kits"
              target="_blank"
              isSmall
            />
          </div>
        </>
      </Container>
    </>
  );
}
