import classNames from 'classnames'
import { ContainerTheme } from '../../Container/Container'
import styles from './ContainerMask.module.scss'

export enum ContainerMaskPosition {
    Top,
    Bottom,
}

interface ContainerMaskProps {
    theme: ContainerTheme
    position: ContainerMaskPosition
    className?: string
}

export default function ContainerMask({
    theme,
    position,
    className,
}: ContainerMaskProps) {
    const topPath =
        'M1440 0H0V9.36383C3.99441 12.0687 8.81276 13.6486 14.0001 13.6486C61.4707 13.6486 100.825 17.6658 144.807 31.295C170.049 39.1174 197.652 40.324 222.918 39.9376C234.702 39.7574 246.496 39.2109 257.606 38.696H257.612L260.344 38.5696C272.431 38.0123 283.609 37.5335 294.02 37.5335H386.474C440.081 37.5335 491.36 35.3409 545.663 23.2913C554.185 21.4003 563.414 18.9467 572.077 16.6432L572.081 16.6423C576.528 15.4598 580.826 14.3171 584.803 13.3103C597.404 10.1207 608.761 7.72955 619.358 6.94574C653.723 4.40399 688.531 5.77588 724.512 7.99481C732.433 8.48328 740.445 9.01593 748.523 9.55304L748.544 9.5545L748.546 9.55469C776.654 11.4235 805.565 13.3457 834.243 13.6472C860.143 13.9194 885.787 13.9294 911.27 13.9394C969.974 13.9623 1027.82 13.985 1085.99 17.2119C1149.91 20.7574 1213.5 23.8849 1278.36 23.8849C1299.01 23.8849 1319.6 25.1962 1341.21 26.572L1341.21 26.5726C1351.15 27.2056 1361.31 27.8524 1371.78 28.3913C1393.94 29.5311 1416.89 30.126 1440 28.6224V0Z'
    const bottomPath =
        'M26.5231 30.1456C18.471 31.1506 9.46697 32.0028 0 32.8856V42H1440V7.75377C1415.64 0.784003 1389.29 -0.307787 1365.08 0.0623455C1353.3 0.242582 1341.5 0.789169 1330.39 1.30403L1327.66 1.43041C1315.57 1.98771 1304.39 2.46645 1293.98 2.46645H1201.53C1147.92 2.46645 1096.64 4.65904 1042.34 16.7086C1033.81 18.5999 1024.58 21.0542 1015.92 23.3578L1015.91 23.359L1015.9 23.362C1011.46 24.5429 1007.17 25.6842 1003.2 26.6897C990.596 29.8794 979.239 32.2705 968.642 33.0543C934.277 35.596 899.469 34.224 863.488 32.0051C855.561 31.5163 847.542 30.9831 839.457 30.4456L839.456 30.4455C811.348 28.5766 782.436 26.6543 753.757 26.3528C727.857 26.0806 702.213 26.0706 676.73 26.0606C618.026 26.0376 560.177 26.015 502.007 22.7881C438.093 19.2426 374.496 16.115 309.639 16.115C288.985 16.115 268.397 14.8038 246.794 13.428C236.852 12.7947 226.694 12.1478 216.216 11.6087C183.898 9.94601 149.874 9.44284 116.164 14.9224C103.379 17.0006 91.5036 19.2041 79.9807 21.3422C62.0085 24.677 44.8936 27.8527 26.5231 30.1456Z'

    const path = position === ContainerMaskPosition.Top ? topPath : bottomPath

    return (
        <svg
            className={className}
            height="42"
            viewBox="0 0 1440 42"
            preserveAspectRatio="none"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className={classNames([
                    theme === ContainerTheme.Blue && styles.root__blue,
                    theme === ContainerTheme.White && styles.root__white,
                ])}
                d={path}
            />
        </svg>
    )
}
