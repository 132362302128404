import classNames from 'classnames'
import { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import styles from './HeroLink.module.scss'
import { createBlob } from './HeroLink.utils'

interface HeroLinkProps {
  href?: string
  target?: string
  text: string
  to?: string
  isSmall?: boolean
}

export default function HeroLink({
  href,
  target,
  text,
  to,
  isSmall,
}: HeroLinkProps) {
  const rootRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null)
  const svgRef = useRef<SVGSVGElement>(null)
  const pathRef = useRef<SVGPathElement>(null)

  useEffect(() => {
    if (rootRef.current && svgRef.current && pathRef.current) {
      const blob = createBlob({
        parent: rootRef.current,
        svg: svgRef.current,
        element: pathRef.current,
        numPoints: 20,
        centerX: rootRef.current.offsetWidth / 2,
        centerY: rootRef.current.offsetHeight / 2,
        minRadius: 5,
        maxRadius: 10,
        minDuration: 0.2,
        maxDuration: 1,
      })
    }
  }, [rootRef, svgRef, pathRef])

  const Root = (props: any) =>
    href ? (
      <a {...props} ref={rootRef}>
        {props.children}
      </a>
    ) : (
      <Link {...props} ref={rootRef}>
        {props.children}
      </Link>
    )

  return (
    <Root className={styles.root} href={href} target={target} to={to}>
      <span
        className={classNames([
          styles['label'],
          isSmall && styles['label--is-small'],
        ])}
      >
        {text}
      </span>

      <svg preserveAspectRatio="none" ref={svgRef}>
        <path ref={pathRef} />
      </svg>
    </Root>
  )
}
