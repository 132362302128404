import Container from '../../components/Container';
import {
  ContainerSize,
  ContainerTheme,
} from '../../components/Container/Container';
import HeroLink from '../../components/HeroLink';
import Squiggle from '../../components/Squiggle';
import { SquiggleTheme } from '../../components/Squiggle/Squiggle';
import collage from '../../images/collage.jpg';
import majorellemix from '../../images/majorelle-mix.jpg';
import TextBlock from '../../components/TextBlock';
import { AppPaths } from '../../constants/paths';
import YoutubePlayer from '../../components/YoutubePlayer';

import styles from './StartPage.module.scss';
import PressQuote from '../../components/PressQuote';
import classNames from 'classnames';

interface StartPageProps {}

export default function StartPage({}: StartPageProps) {
  return (
    <>
      <Container theme={ContainerTheme.BlueLogo} size={ContainerSize.Large}>
        <>
          <div>
            <HeroLink
              href="mailto:contact@majorelleblue.com"
              text="Say hi to us"
            />

            <Squiggle theme={SquiggleTheme.White1} />

            <HeroLink
              href="https://open.spotify.com/artist/6TNEud8QNzjmtkZSCSW85v?si=pgSTdMLZS2qYEXwndfvn0g"
              text="Listen to us"
              target="_blank"
            />
          </div>

          <div>
            <HeroLink
              href="https://www.instagram.com/__majorelleblue/"
              text="Hang out with us"
              target="_blank"
            />

            <Squiggle theme={SquiggleTheme.White2} />

            <HeroLink
              href="https://www.facebook.com/majorelleblueband/events/"
              text="See us IRL"
              target="_blank"
            />
          </div>

          <div>
            <HeroLink
              href="https://majorelleblue.bandcamp.com/releases"
              text="Buy stuff"
              target="_blank"
            />

            <Squiggle theme={SquiggleTheme.White3} />

            <HeroLink to={AppPaths.Press} text="Press things" />
          </div>
        </>
      </Container>

      <Container
        topMask={ContainerTheme.Blue}
        bottomMask={ContainerTheme.Blue}
        isMaskOverlaid
        keepSmall={true}
        isPadded={false}
        dontCenterInner
      >
        <>
          <img
            src={majorellemix}
            alt="Two renaissance inspired photos of the band"
          />
        </>
      </Container>

      <Container
        theme={ContainerTheme.Blue}
        bottomMask={ContainerTheme.White}
        size={ContainerSize.Full}
        className={styles.quoteSquiggle}
        isPadded
      >
        <>
          <PressQuote
            source=""
            quote="Constantly exploring new sonic territory, while
          remaining true to the 90/00's DIY vibes. Here for the indie renaissance."
          />

          <PressQuote
            source=""
            quote="Noisy and catchy post-depression party music. An optimistically hopeless indie dream for the ambivalent."
          />

          <PressQuote
            source=""
            quote="The messy (but supportive) best friend in your rom-com."
          />

          <PressQuote
            quote=""
            source="Also, great at making up semi-cheesy emotional descriptions of our music."
          />
        </>
      </Container>

      <Container
        theme={ContainerTheme.White}
        bottomMask={ContainerTheme.Blue}
        isMaskOverlaid
        keepSmall={true}
        isPadded
      >
        <>
          <h2>Latest video</h2>
          <div
            className={classNames(
              styles['video-container'],
              styles['video-container__is-large']
            )}
          >
            <YoutubePlayer embedId="asjlpv65jP4" />
          </div>
        </>
      </Container>

      <Container
        theme={ContainerTheme.Blue}
        bottomMask={ContainerTheme.White}
        isMaskOverlaid
        keepSmall={true}
        isPadded={false}
      >
        <>
          <div className={styles['video-container-container']}>
            <div className={styles['video-container']}>
              <YoutubePlayer embedId="v9w48OlFYQg" />
            </div>

            <div className={styles['video-container']}>
              <YoutubePlayer embedId="QoP8N7vXIPw" />
            </div>

            <div className={styles['video-container']}>
              <YoutubePlayer embedId="5lLTWeScxO0" />
            </div>
          </div>
        </>
      </Container>

      <Container theme={ContainerTheme.White} size={ContainerSize.Small}>
        <>
          <TextBlock>
            <p>
              It all started with <b>Nellie</b> and her bleached, blue hair.
              When deciding on a band name, the 5 members did what dorks do best
              - they got out the post-its and had a formal workshop. After hours
              of discussions, vision boards and heaps of crumbled up sticky
              notes, they were still stuck. As a last resort they went on
              Wikipedia in search of blue hues and found “Majorelle Blue”.{' '}
              <b>Sofia</b>, <b>Adam</b>, <b>Nils</b> and <b>Kotte</b> loved it,
              and Nellie promised to keep dyeing her hair blue forever.
            </p>
          </TextBlock>

          <div>
            <HeroLink href="mailto:contact@majorelleblue.com" text="Book us" />

            <Squiggle theme={SquiggleTheme.Blue1} />

            <HeroLink
              href="https://open.spotify.com/artist/6TNEud8QNzjmtkZSCSW85v?si=pgSTdMLZS2qYEXwndfvn0g"
              text="Listen to us"
              target="_blank"
            />
          </div>
        </>
      </Container>
    </>
  );
}
