import React from 'react'

interface SkipProps {}

export default function Skip({}: SkipProps) {
    return (
        <svg
            width="21"
            height="24"
            viewBox="0 0 21 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect id="rect" x="19" y="4" width="2" height="16" />
            <path
                d="M1 2L14.3333 12L1 22L1 2Z"
                stroke="white"
                strokeWidth="2"
            />
        </svg>
    )
}
