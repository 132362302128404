import styles from './PressQuote.module.scss'

interface PressQuoteProps {
    quote: string
    source: string
}

export default function PressQuote({ quote, source }: PressQuoteProps) {
    return (
        <div className={styles.root}>
            <p className={styles.quote}>{quote}</p>
            <p className={styles.source}>{source}</p>
        </div>
    )
}
