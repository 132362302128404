import classNames from 'classnames'

import styles from './Squiggle.module.scss'

export enum SquiggleTheme {
  White1,
  White2,
  White3,
  Blue1,
}

interface SquiggleProps {
  theme: SquiggleTheme
  isSmall?: boolean
}

export default function Squiggle({ theme, isSmall }: SquiggleProps) {
  return (
    <div
      className={classNames([
        styles.root,
        theme === SquiggleTheme.White1 && styles.root__white1,
        theme === SquiggleTheme.White2 && styles.root__white2,
        theme === SquiggleTheme.White3 && styles.root__white3,
        theme === SquiggleTheme.Blue1 && styles.root__blue1,
        isSmall && styles['root--is-small'],
      ])}
    />
  )
}
