import styles from './Container.module.scss';
import classNames from 'classnames';
import ContainerMask from '../_svg/MaskBottom';
import { ContainerMaskPosition } from '../_svg/MaskBottom/ContainerMask';

export enum ContainerTheme {
  White,
  Blue,
  Black,
  BlueLogo,
}

export enum ContainerSize {
  Small,
  Large,
  Medium,
  Full,
}

interface ContainerProps {
  theme?: ContainerTheme;
  size?: ContainerSize;
  topMask?: ContainerTheme | null;
  bottomMask?: ContainerTheme | null;
  isPadded?: boolean;
  isMaskOverlaid?: boolean;
  dontCenterInner?: boolean;
  keepSmall?: boolean;
  children: JSX.Element;
  className?: string;
}

export default function Container({
  theme = ContainerTheme.White,
  size = ContainerSize.Full,
  topMask = null,
  bottomMask = null,
  isPadded = true,
  isMaskOverlaid,
  dontCenterInner,
  keepSmall,
  children,
  className,
}: ContainerProps) {
  return (
    <section
      className={classNames([
        Boolean(className) && className,
        styles.root,
        isPadded && styles.root__padded,
        theme === ContainerTheme.White && styles.root__white,
        theme === ContainerTheme.Blue && styles.root__blue,
        theme === ContainerTheme.BlueLogo && styles.root__blueLogo,
        theme === ContainerTheme.Black && styles.root__black,
        !keepSmall && styles.root__heightAdjusted,
      ])}
    >
      {theme === ContainerTheme.BlueLogo && (
        <div className={styles.topSquiggle} />
      )}

      {topMask !== null && (
        <ContainerMask
          className={classNames([
            styles.containerMask,
            isMaskOverlaid && styles.containerMask__overlaidTop,
          ])}
          theme={topMask}
          position={ContainerMaskPosition.Top}
        />
      )}

      <div
        className={classNames([
          styles.inner,
          size === ContainerSize.Small && styles.inner__small,
          size === ContainerSize.Large && styles.inner__large,
          size === ContainerSize.Medium && styles.inner__medium,
          isPadded && styles.inner__paddedVertical,
          dontCenterInner && styles.inner__dontCenter,
          !dontCenterInner && styles.inner__center,
        ])}
      >
        {children}
      </div>

      {bottomMask !== null && (
        <ContainerMask
          className={classNames([
            styles.containerMask,
            isMaskOverlaid && styles.containerMask__overlaidBottom,
          ])}
          theme={bottomMask}
          position={ContainerMaskPosition.Bottom}
        />
      )}
    </section>
  );
}
