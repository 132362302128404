import Container from '../../components/Container';
import { ContainerTheme } from '../../components/Container/Container';
import HeroLink from '../../components/HeroLink';
import Squiggle from '../../components/Squiggle';
import { SquiggleTheme } from '../../components/Squiggle/Squiggle';
import { AppPaths } from '../../constants/paths';
import cover from '../../images/cover.jpg';
import styles from './ReleasePage.module.scss';

interface ReleasePageProps {}

export default function ReleasePage({}: ReleasePageProps) {
  return (
    <>
      <Container theme={ContainerTheme.BlueLogo}>
        <>
          <img className={styles['cover']} src={cover} alt="Cover" />

          <h1 className={styles['title']}>
            Our latest EP is called "Waterproof Mascara"
          </h1>

          <div>
            <HeroLink
              href="https://open.spotify.com/album/5DjEILp8TaOQyzwjOb0xpU?si=0v3DNMIkStKnTxe_dVF2yA"
              text="Listen on Spotify"
              target="_blank"
              isSmall
            />

            <Squiggle theme={SquiggleTheme.White1} isSmall />

            <HeroLink
              href="https://www.youtube.com/watch?v=asjlpv65jP4"
              text="Watch the latest video"
              target="_blank"
              isSmall
            />
          </div>

          <div>
            <HeroLink
              href="https://www.instagram.com/__majorelleblue/"
              text="Hang out with us"
              target="_blank"
              isSmall
            />

            <Squiggle theme={SquiggleTheme.White2} isSmall />

            <HeroLink
              href="https://www.facebook.com/majorelleblueband/events/"
              text="See us IRL"
              target="_blank"
              isSmall
            />
          </div>
          <HeroLink
            href="http://www.majorelleblue.com/press"
            text="Press things"
            target="_blank"
            isSmall
          />
        </>
      </Container>
    </>
  );
}
