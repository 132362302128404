import React from 'react'

interface PauseProps {}

export default function Pause({}: PauseProps) {
    return (
        <svg
            width="30"
            height="32"
            viewBox="0 0 30 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="6"
                y="1"
                width="6"
                height="30"
                stroke="white"
                strokeWidth="2"
            />
            <rect
                x="18"
                y="1"
                width="6"
                height="30"
                stroke="white"
                strokeWidth="2"
            />
        </svg>
    )
}
