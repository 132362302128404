import { TimelineMax, TweenMax, Sine } from 'gsap'

export function createBlob(options: any) {
  const parentHeight = options.parent.offsetHeight
  const parentWidth = options.parent.offsetWidth
  const viewBoxVal =
    parentWidth * -0.5 +
    ' ' +
    parentHeight * -0.5 +
    ' ' +
    parentWidth * 2 +
    ' ' +
    parentHeight * 2
  options.svg.setAttribute('width', parentWidth)
  options.svg.setAttribute('height', parentHeight)
  options.svg.setAttribute('viewBox', viewBoxVal)
  const points: any[] = []
  const path = options.element
  const slice = (Math.PI * 2) / options.numPoints
  const startAngle = random(Math.PI * 2)

  const tl = new TimelineMax({
    onUpdate: update,
  })

  // const modX = 2.5
  // const modY = 2.7
  const modX = 2
  const modY = 2
  for (let i = 0; i < options.numPoints; i++) {
    const angle = startAngle + i * slice
    const duration = random(options.minDuration, options.maxDuration)

    const point = {
      x:
        options.centerX +
        options.centerX *
          modX *
          Math.cos(angle) *
          0.07 *
          (options.minRadius * 0.7),
      y:
        options.centerY +
        options.centerY * modY * Math.sin(angle) * 0.07 * options.minRadius,
    }

    const tween = TweenMax.to(point, duration, {
      x:
        options.centerX +
        options.centerX *
          modX *
          Math.cos(angle) *
          0.07 *
          (options.maxRadius * 0.7),
      y:
        options.centerY +
        options.centerY * modY * Math.sin(angle) * 0.07 * options.maxRadius,
      repeat: -1,
      yoyo: true,
      ease: Sine.easeInOut,
    })

    tl.add(tween, -random(duration))
    points.push(point)
  }

  options.tl = tl
  options.points = points

  function update() {
    path.setAttribute('d', cardinal(points, true, 1))
  }

  return options
}

// Cardinal spline - a uniform Catmull-Rom spline with a tension option
function cardinal(data: any, closed: any, tension: any) {
  if (data.length < 1) return 'M0 0'
  if (tension == null) tension = 1

  const size = data.length - (closed ? 0 : 1)
  let path = 'M' + data[0].x + ' ' + data[0].y + ' C'

  for (var i = 0; i < size; i++) {
    let p0, p1, p2, p3

    if (closed) {
      p0 = data[(i - 1 + size) % size]
      p1 = data[i]
      p2 = data[(i + 1) % size]
      p3 = data[(i + 2) % size]
    } else {
      p0 = i == 0 ? data[0] : data[i - 1]
      p1 = data[i]
      p2 = data[i + 1]
      p3 = i == size - 1 ? p2 : data[i + 2]
    }

    const x1 = p1.x + ((p2.x - p0.x) / 6) * tension
    const y1 = p1.y + ((p2.y - p0.y) / 6) * tension

    const x2 = p2.x - ((p3.x - p1.x) / 6) * tension
    const y2 = p2.y - ((p3.y - p1.y) / 6) * tension

    path += ' ' + x1 + ' ' + y1 + ' ' + x2 + ' ' + y2 + ' ' + p2.x + ' ' + p2.y
  }

  return closed ? path + 'z' : path
}

function random(min: number, max?: number) {
  if (!max) {
    max = min
    min = 0
  }

  if (min > max) {
    var tmp = min
    min = max
    max = tmp
  }
  return min + (max - min) * Math.random()
}
